import React from "react";
import { icons } from "lucide-react";
//import "lucide-static/font/lucide.css";

export default function IconRenderer({
  icon,
  color,
  size,
  debug,
  nonFixedHeight,
}: {
  icon: any;
  color?: string;
  size?: number;
  debug?: boolean;
  nonFixedHeight?: boolean;
}) {
  if (!icon || !icon.length) return null;

  if (icon[0].component === "customIcon") {
    return <CustomIcon nonFixedHeight={nonFixedHeight} icon={icon[0]} size={size} />;
  }
  return <PresetIcon icon={icon[0].icon} color={color} size={size} debug={debug} />;
}

const CustomIcon = ({ icon, size, nonFixedHeight }) => {
  return (
    <img
      src={icon.customIcon.filename}
      alt={icon.customIcon.alt}
      style={{ width: size, height: nonFixedHeight ? "auto" : size }}
    />
  );
};

const PresetIcon = ({
  icon,
  size = 16,
  strokeWidth = 1,
  color,
  debug,
}: {
  icon: any;
  size?: number;
  strokeWidth?: number;
  color?: string;
  debug?: boolean;
}) => {
  if (!icon?.iconId || !icon) return null;
  let name = icon.iconId.replace("icon-", "");
  name = name
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
  const LucideIcon = icons[name];

  if (!LucideIcon) return null;
  return <LucideIcon color={color ? color : icon.iconColor} size={size} style={{ strokeWidth: `${strokeWidth}px` }} />;
};
