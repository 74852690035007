export type ThemeOption = "white" | "lightGray" | "mediumGray";

export interface ThemeProperties {
  backgroundColor: string;
  headerBackgroundWithOpacity: string;
  textColor: string;
  accentColor: string;
}

export type CtaThemeOption = "ppAccent" | "textPrimary" | "textSecondary";

const themeMap: Record<ThemeOption, ThemeProperties> = {
  white: {
    backgroundColor: "#FFFFFF",
    textColor: "#000000",
    accentColor: "#4A7B55",
    headerBackgroundWithOpacity: "#ffffff",
  },
  lightGray: {
    backgroundColor: "#F9FAFB",
    headerBackgroundWithOpacity: "#f9fafbd3",
    textColor: "#333333",
    accentColor: "#4A7B55",
  },
  mediumGray: {
    backgroundColor: "#F3F4F6",
    textColor: "#333333",
    accentColor: "#4A7B55",
    headerBackgroundWithOpacity: "#f3f4f6d3",
  },
};

type CtaThemeProperty = string;

const ctaThemeMap: Record<CtaThemeOption, CtaThemeProperty> = {
  ppAccent: "#4A7B55",
  textPrimary: "#10181C",
  textSecondary: "#677076",
};

export function themeProvider(theme: ThemeOption): ThemeProperties {
  return themeMap[theme] || themeMap.white; // Default to White theme if invalid option is provided
}

export function themeProviderWithInheritance({
  theme,
  parentTheme,
  inheritTheme,
}: {
  theme: ThemeOption;
  parentTheme: ThemeOption;
  inheritTheme: boolean;
}): ThemeProperties {
  if (inheritTheme && parentTheme) {
    return themeProvider(parentTheme);
  }
  if (parentTheme && inheritTheme === undefined) {
    return themeProvider(parentTheme);
  }
  return themeProvider(theme);
}

export function ctaThemeProvider(theme: CtaThemeOption): CtaThemeProperty {
  return ctaThemeMap[theme] || ctaThemeMap.ppAccent;
}
