import { FloatingPortal } from "@floating-ui/react";
import styled, { css } from "styled-components";
import { HubspotProvider, useHubspotForm } from "next-hubspot";
import mq from "@/utils/mq";
import { setCookie } from "react-use-cookie";
import { useHotkeys } from "react-hotkeys-hook";
import useOnclickOutside from "react-cool-onclickoutside";

const ContentWallOuterWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999;
`;

const ContentWallInnerWrapper = styled.div`
  width: min(600px, 98vw);
  height: fit-content;
  background-color: #fff;
  padding: 26px;
  border-radius: 8px;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  width: 100%;
  box-sizing: content-box;
  padding: 1px;
  overflow: hidden;

  * {
    font-family: var(--font-inter);
  }
  #hubspot-form-wrapper {
    margin-top: 1.75rem;
  }
  form,
  fieldset,
  input,
  .hs-input {
    width: 100% !important;
    max-width: 100% !important;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .form-columns-2 {
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
  .hs-form-field {
    display: flex;
    flex-direction: column;
  }
  input,
  textarea {
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: rgb(3 7 18 / 1);
    font-size: 14px;
    padding-left: 0.75rem;
    padding-right: 0rem;
    flex-grow: 1;
    min-width: 0;
    border: 0px solid transparent;
    box-shadow:
      0 0 0 1px rgba(17, 24, 28, 0.08),
      0 1px 2px -1px rgba(17, 24, 28, 0.08),
      0 2px 4px rgba(17, 24, 28, 0.04);
    font-size: 13px;
    background-color: rgb(255 255 255 / 1);
    border-radius: 0.5rem;
    min-height: 32px;
    &.invalid,
    &.error {
      border: 1px solid red;
    }
  }

  textarea {
    padding: 0.75rem;
    min-height: 100px;
  }

  select {
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: rgb(3 7 18 / 1);
    font-size: 14px;
    padding-left: 0.75rem;
    padding-right: 1rem; // Changed from 0rem to 1rem
    flex-grow: 1;
    min-width: 0;
    border: 0px solid transparent;
    box-shadow:
      0 0 0 1px rgba(17, 24, 28, 0.08),
      0 1px 2px -1px rgba(17, 24, 28, 0.08),
      0 2px 4px rgba(17, 24, 28, 0.04);
    font-size: 13px;
    background-color: rgb(255 255 255 / 1);
    border-radius: 0.5rem;
    min-height: 40px;
    cursor: pointer;
    appearance: none; // Added to ensure consistent styling across browsers
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center; // Positions the dropdown indicator
  }
  label,
  legend {
    padding-bottom: 0.5rem;
    color: var(--PP-Text-Secondary);
    font-size: 13px;
    line-height: 19.5px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
    margin: 0;
    margin-top: 0.5rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    padding: 0 0.5rem;
  }

  input[type="submit"] {
    white-space: nowrap;
    border: none;
    color: #09090b;
    outline: none;
    box-shadow:
      0px 1px 2px 0px rgba(9, 9, 11, 0.12),
      0px 0px 0px 1px rgba(9, 9, 11, 0.08);
    background: #fff;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    width: fit-content !important;
    padding: 6.5px 24px;
    transition: background var(--ease-out-expo) 0.4s;

    &:hover {
      background: #f6f6f6;
    }

    ${mq.mobile(css`
      font-size: 12px;
      line-height: 18px;
      padding: 5px 10px;
    `)};
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 22px;
`;

const Title = styled.div`
  margin: 0;
  color: var(--PP-Text-Primary, #10181c);
  text-align: left;
  font-family: var(--font-inter);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-wrap: pretty;
  ${mq.mobile(css`
    max-width: unset;
    font-size: 28px;
    line-height: 36px;
  `)};
`;

const Description = styled.div`
  color: var(--PP-Text-Secondary, #677076);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  max-width: 440px;
  text-wrap: pretty;
  @media (max-width: 900px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 600px;
    text-wrap: pretty;
  }
  ${mq.mobile(css`
    font-size: 16px;
    line-height: 28px;
  `)};
`;

export default function ContentWall({
  contentWall,
  handleFormSubmitted,
  setDisplayContentWall,
}: {
  contentWall: any;
  handleFormSubmitted: () => void;
  setDisplayContentWall: (display: boolean) => void;
}) {
  const ref = useOnclickOutside(() => {
    setDisplayContentWall(false);
  });

  useHotkeys("escape", () => {
    setDisplayContentWall(false);
  });

  return (
    <FloatingPortal id='content-wall'>
      <ContentWallOuterWrapper>
        <ContentWallInnerWrapper ref={ref}>
          <TitleContainer>
            <Title>{contentWall.title}</Title>
            <Description>{contentWall.description}</Description>
          </TitleContainer>
          <HubspotProvider>
            <HubspotFormContent
              formId={contentWall.hubspotFormID}
              cookieID={contentWall.cookieID}
              handleFormSubmitted={handleFormSubmitted}
              lastProductOrServiceType={contentWall.lastProductOrServiceType}
              lastDownloadType={contentWall.lastDownloadType}
            />
          </HubspotProvider>
        </ContentWallInnerWrapper>
      </ContentWallOuterWrapper>
    </FloatingPortal>
  );
}

function HubspotFormContent({ formId, cookieID, handleFormSubmitted, lastProductOrServiceType, lastDownloadType }) {
  const portalId = process.env.NEXT_PUBLIC_PORTAL_ID;
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: portalId,
    formId: formId,
    target: "#hubspotForm",
    onFormSubmitted: (props) => {
      setCookie(cookieID, "true", {
        days: 365,
      });
      handleFormSubmitted();
    },
    onFormReady: (form) => {
      // Find and set the product name field
      const productNameInput = form.querySelector('input[name="last_product_or_service_type"]') as HTMLInputElement;
      if (productNameInput) {
        productNameInput.value = lastProductOrServiceType;
      }

      // Find and set the download type field
      const downloadTypeInput = form.querySelector('input[name="last_download_type"]') as HTMLInputElement;
      if (downloadTypeInput) {
        downloadTypeInput.value = lastDownloadType;
      }
    },
  });

  return (
    <FormWrapper>
      <FormContainer id='hubspotForm'>{!loaded && <div>Loading...</div>}</FormContainer>
    </FormWrapper>
  );
}
