import { useRouter } from "next/router";
import { TailSpin } from "react-loader-spinner";
import styled, { css } from "styled-components";
import { getCookie } from "react-use-cookie";

import ArrowRight from "@/assets/svg/arrowRight.svg";
import IconRenderer from "@/components/Shared/IconRenderer";
import mq from "@/utils/mq";
import { useState } from "react";
import ContentWall from "@/components/ContentWall/ContentWall";
import Link from "next/link";
import parseStoryblokLink from "@/utils/parseStoryblokLink";

const ButtonIcon = styled.div`
  color: #737373d6;
  transition: var(--ease-out-expo) 0.4s;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const ButtonWrapper = styled.button<{
  $disabled?: boolean;
  $styleVariant?: "primary" | "secondary" | "ghost" | "resourceCentre";
}>`
  white-space: nowrap;
  border: none;
  color: #09090b;
  outline: none;
  box-shadow:
    0px 1px 2px 0px rgba(9, 9, 11, 0.12),
    0px 0px 0px 1px rgba(9, 9, 11, 0.08);
  background: #fff;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  gap: 0.375rem;
  width: fit-content;
  padding: 6.5px 12px;
  transition: background var(--ease-out-expo) 0.4s;

  &:hover {
    background: #f6f6f6;
  }

  ${ButtonIcon} {
    color: #171717;
    transition: var(--ease-out-expo) 0.4s;
  }

  ${(props) =>
    props.$disabled &&
    css`
      background: #f6f6f6;
      color: #1717171f;
      opacity: 0.5;
      cursor: not-allowed;
      ${ButtonIcon} {
        color: #737373d6;
      }
    `}

  ${mq.mobile(css`
    font-size: 12px;
    line-height: 18px;
    padding: 5px 10px 5px 10px;
    gap: 0.2rem;
  `)};
  ${(props) =>
    props.$styleVariant === "resourceCentre" &&
    css`
      border: 1px solid #dbdcdd;
      background: #fcfcfc;
      border-radius: 8px;
      color: var(--PP-Text-Primary);
      font-feature-settings:
        "liga" off,
        "clig" off;
      font-family: var(--font-inter);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 8px 14px;
      gap: 8px;
    `}
`;

const ButtonLabel = styled.span<{ $styleVariant?: "primary" | "secondary" | "ghost" | "resourceCentre" }>`
  margin: 0;
  padding: 0;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.084px;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  ${(props) =>
    props.$styleVariant === "resourceCentre" &&
    css`
      font-size: 13px;
      line-height: 20px;
    `}
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8080d81f;
  backdrop-filter: blur(3px) contrast(90%);
  color: #4165dc;
`;

const ButtonArrow = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ButtonArrowInner = styled.span`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #09090b;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  color: inherit;
`;

interface ButtonProps {
  label: string;
  link?: string;
  labelColor?: string;
  style: "primary" | "secondary" | "ghost" | "resourceCentre";
  iconPosition?: "hidden" | "left" | "right";
  icon?: React.ReactNode;
  customIcon?: React.ReactNode;
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  target?: string;
  loading?: boolean;
  enableArrow?: boolean;
  contentWall?: any[];
}

export default function Button({
  label,
  link,
  target,
  action,
  loading,
  labelColor,
  style,
  iconPosition = "hidden",
  icon,
  customIcon,
  enableArrow,
  disabled,
  contentWall,
}: ButtonProps) {
  const [displayContentWall, setDisplayContentWall] = useState(false);

  const router = useRouter();
  const handleClick = ({
    e,
    skipContentWallCheck,
  }: {
    e?: React.MouseEvent<HTMLButtonElement>;
    skipContentWallCheck?: boolean;
  }) => {
    if (contentWall && contentWall.length > 0 && !skipContentWallCheck) {
      const cookie = getCookie(contentWall[0]?.cookieID || "");

      if (!cookie) {
        setDisplayContentWall(true);
        return;
      }
    }

    if (action) {
      action(e);
    }
    if (link) {
      if (target === "_blank") {
        window.open(link, "_blank");
        return;
      }

      router.push(link);
    }
  };

  const handleFormSubmitted = () => {
    handleClick({ skipContentWallCheck: true });
    setDisplayContentWall(false);
  };

  return (
    <ButtonLinkWrapper link={link} target={target} hasContentWall={contentWall?.length > 0}>
      <ButtonWrapper $disabled={disabled} onClick={(e) => handleClick({ e })} $styleVariant={style}>
        {displayContentWall && contentWall && contentWall.length > 0 && (
          <ContentWall
            contentWall={contentWall[0]}
            handleFormSubmitted={handleFormSubmitted}
            setDisplayContentWall={setDisplayContentWall}
          />
        )}
        {loading && (
          <LoadingOverlay>
            <TailSpin width={20} color='#fff' strokeWidth={6} />
          </LoadingOverlay>
        )}
        {iconPosition === "left" && customIcon && (
          <IconContainer style={{ marginRight: "2px" }}>{customIcon}</IconContainer>
        )}
        {iconPosition === "left" && icon && (
          <IconContainer style={{ marginRight: "2px" }}>
            <IconRenderer icon={icon} />
          </IconContainer>
        )}
        <ButtonLabel $styleVariant={style}>{label}</ButtonLabel>
        {iconPosition === "right" && customIcon && (
          <IconContainer style={{ marginLeft: "2px" }}>{customIcon}</IconContainer>
        )}
        {iconPosition === "right" && icon && (
          <IconContainer style={{ marginLeft: "2px" }}>
            <IconRenderer icon={icon} />
          </IconContainer>
        )}
        {enableArrow && (
          <ButtonArrow>
            <ButtonArrowInner>
              <ArrowRight />
            </ButtonArrowInner>
          </ButtonArrow>
        )}
      </ButtonWrapper>
    </ButtonLinkWrapper>
  );
}

const ButtonLinkWrapper = ({ link, target, children, hasContentWall }) => {
  if (link && !hasContentWall) {
    return (
      <Link href={link} target={target} style={{ textDecoration: "none" }}>
        {children}
      </Link>
    );
  }

  return <>{children}</>;
};
