import styled from "styled-components";
import { BREAKPOINT_SM } from "@/constants";
import { themeProvider, ThemeOption } from "@/utils/ThemeProvider";
import { css } from "styled-components";
import mq from "@/utils/mq";

const ContentSection = styled.section`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const ContentWrapperContainer = styled.div<{
  $noMobilePadding?: boolean;
  $addVerticalPadding?: boolean;
  $addVerticalPaddingTop?: boolean;
  $addVerticalPaddingBottom?: boolean;
}>`
  max-width: 92.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding-left: 22px;
  padding-right: 22px;
  ${({ $noMobilePadding }) =>
    $noMobilePadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  ${({ $addVerticalPaddingTop }) =>
    $addVerticalPaddingTop &&
    css`
      padding-top: 6rem;

      ${mq.mobile(css`
        padding-top: 2.5rem;
      `)};
    `}
  ${({ $addVerticalPaddingBottom }) =>
    $addVerticalPaddingBottom &&
    css`
      padding-bottom: 6rem;
      ${mq.mobile(css`
        padding-bottom: 2.5rem;
      `)};
    `}
  @media (min-width: ${BREAKPOINT_SM}px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

export default function ContentWrapper({
  backgroundColor,
  theme,
  transparent,
  children,
  noMobilePadding,
  addVerticalPaddingTop,
  addVerticalPaddingBottom,
}: {
  backgroundColor?: string;
  theme?: ThemeOption;
  transparent?: boolean;
  noMobilePadding?: boolean;
  addVerticalPadding?: boolean;
  addVerticalPaddingTop?: boolean;
  addVerticalPaddingBottom?: boolean;
  children: React.ReactNode;
}) {
  const themeProps = theme ? themeProvider(theme) : null;

  return (
    <ContentSection
      style={{
        backgroundColor: transparent
          ? "transparent"
          : backgroundColor || (themeProps ? themeProps.backgroundColor : "#F9FAFB"),
      }}>
      <ContentWrapperContainer
        $noMobilePadding={noMobilePadding}
        $addVerticalPaddingTop={addVerticalPaddingTop}
        $addVerticalPaddingBottom={addVerticalPaddingBottom}>
        {children}
      </ContentWrapperContainer>
    </ContentSection>
  );
}
